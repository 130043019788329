import UniversalLink from 'components/UniversalLink'
import { ReactComponent as ArrowSVG } from 'images/global/anon-arrow.svg'
import { fmobile, fresponsive, ftablet } from 'library/fullyResponsive'
import styled, { css } from 'styled-components'
import colors from 'styles/colors'
import textStyles from 'styles/text'

export default function Banner() {
  return (
    <Wrapper>
      <Link to="https://runway.com/launch">
        <span>We&apos;ve officially launched! Learn more</span>
        <Arrow />
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${colors.primaryYellow};
  color: ${colors.primaryBlack};
  position: relative;
  z-index: 1;
  border-radius: 100px;

  svg {
    background: ${colors.primaryBlack};

    path {
      fill: ${colors.primaryYellow};
    }
  }
`

const Link = styled(UniversalLink)`
  ${textStyles.title4}
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  :hover {
    svg {
      transform: rotate(45deg);
    }
  }

  ${fresponsive(css`
    margin-top: 8px;
    height: 35px;
  `)}
  ${fmobile(css`
    margin-top: 4px;
    ${textStyles.title5};
  `)}
`

const Arrow = styled(ArrowSVG)`
  height: auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 100%;

  ${fresponsive(css`
    width: 16px;
  `)}
  ${ftablet(css`
    width: 12px;
  `)};
  ${fmobile(css`
    margin-top: -2px;
  `)}
`
